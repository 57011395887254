import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Section from '../components/section';
import Frame from '../components/frame';

import { dimensions } from '../shared/styles';
import content from '../data/static-content.js';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSection = styled(Section).attrs(props => ({
  wrapperStyle : {
    height : '100%'
  }
}))`
  height : 100%;
  padding-top : ${dimensions.headerHeight};
  margin-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : flex-start;

  & p {font-size : 3rem;}
`;

const MainFrame = styled(Frame)`
  max-width : 95%;
  margin : ${dimensions.headerHeight};

  @media screen and (max-width : 425px) {
    margin : ${dimensions.headerHeight} 4rem;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="Blog" />
    <MainSection>
      <MainFrame tr={{animation:"in-out"}} bl={{animation:"rotate-ticks", color:"pink"}} contentStyle={{paddingBottom : '2rem'}}>
        <h1>{content.blog.title}</h1>
        <p>{content.blog.description}</p>
      </MainFrame>
    </MainSection>
  </Layout>
)

export default NotFoundPage
